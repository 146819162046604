import * as React from "react";
import './scorecard.styles.css';

import { Game } from '../models/models';

export interface ScorecardProps {
    game: Game;
}

export interface ScorecardState {
}
  
export default class Scorecard extends React.Component<ScorecardProps, ScorecardState> {
    public render() {
        const { status, visiting, home, streams } = this.props.game;

        return (
            <div className="scorecard">
                <div className="scoreboard">
                    <div className="team visiting">
                        <img className="logo" src={visiting.image} alt="away-team-logo"></img>
                        <div className="name">{visiting.triCode}</div>
                        <div className="score">{status.visitingScore}</div>
                    </div>
                    <div className="divider"><p>{status.period} {status.clock}</p></div>
                    <div className="team home">
                        <img className="logo" src={home.image} alt="home-team-logo"></img>
                        <div className="name">{home.triCode}</div>
                        <div className="score">{status.homeScore}</div>
                    </div>
                    <div className="links">
                        {/* <span className="thread"><a href={"http://www.google.com"}><b>/r/nba</b></a></span> */}
                            {streams ? streams.map((s,i) => {
                                return (
                                    <span key={i} className="stream"><a href={s.url} target="_blank" rel="noopener noreferrer"><b>{s.name}</b></a></span>
                                )
                            }) : <></>}
                    </div>
                </div>
               
            </div>
        )
    }
}