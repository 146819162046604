import chroma from 'chroma-js';
import teams from './teams.json';
import moment from 'moment';
import { Game, NBAData, Period } from '../models/models.js';

export function sortByStatus(a: Game, b: Game) {
    const periodA = a.status.period.charAt(1);
    const periodB = b.status.period.charAt(1);
    let clockA = a.status.clock.replace(':', '');
    let clockB = a.status.clock.replace(':', '');

    if (periodA === periodB) {
        if (clockA < clockB) {
            return -1;
        } else if (clockA === clockB) {
            return 0;
        } else {
            return 1;
        }
    }

    if (periodA > periodB) {
        return -1;
    } else {
        return 1;
    }
}

export function getGameState(game: NBAData) {
    // const period = parseInt(game.status.period.charAt(1));
    if (game.game.clock) return 'Active';
    else return 'Completed';
}

export function getTeamName (code: string) {
    const data = teams.league;
    const allTeams = [...data.standard, ...data.utah, ...data.vegas, ...data.africa, ...data.sacramento];
    const team = allTeams.filter(t => t.tricode === code);

    if (team.length > 0) return team[0].fullName;

    return "";
}

export function getTeamLogo(triCode: string) {
    const edges = ['SLA', 'NOP', 'GUA', 'NZB'];
    if (edges.includes(triCode)) return process.env.PUBLIC_URL + '/placeholder.png';
    return `https://a.espncdn.com/i/teamlogos/nba/500/${triCode.toLowerCase()}.png`;
}

export function getPeriodName(periodData: Period, status: number, startTime: string) {
    const period = periodData.current;

    let text = "";

    if (periodData.isEndOfPeriod) {
      text += "END OF ";
    }

    if (status === 3) {
      return "FINAL";
    }
    if (period === 0) {
        const start = moment(startTime);
        return start.format('h:mm A');
    } else if (period === 1) {
        text += "Q1";
    } else if (period === 2) {
        text += "Q2";
    } else if (period === 3) {
        text += "Q3";
    } else if (period === 4) {
        text += "Q4";
    } else if (period > 4) {
        text += "OT" + (period - 4);
    } else {
        return "FINAL";
    }

    return text;
}

export const colourStyles = {
        //@ts-ignore
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    //@ts-ignore
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
                //@ts-ignore
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
        //@ts-ignore

    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
        //@ts-ignore

    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
        //@ts-ignore

    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };