import React from 'react';
import Games from './components/games';

const App: React.FC = () => {
  return (
      <Games/>
  );
}

export default App;
