import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Pusher from 'pusher-js';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import Scorecard from './scorecard';
import './scorecard.styles.css';
import lakers from './lakers.png';

import { Team, Status, Game, NBAData, RedditStreamData } from '../models/models'; 
import { getTeamLogo, getPeriodName, getGameState, getTeamName } from '../helpers/utilities';

export interface GamesProps {

}
  
export interface GamesState {
    games: Game[];
    displayed: Game[];
}

export default class Games extends React.Component<GamesProps, GamesState> {
    constructor(props: GamesProps) {
        super(props);

        this.state = {
            games: [],
            displayed: []
        };
    }

    public async componentDidMount() {
        Pusher.logToConsole = true;

        var pusher = new Pusher('3f7270af7b81d227de0a', {
          cluster: 'us3',
          forceTLS: true
        });

        var channel = pusher.subscribe('nba');

        channel.bind('game-updates', (data: NBAData[]) => this.mapNBAResponse(data));
        channel.bind('stream-updates', (data: RedditStreamData[]) => this.mapRedditResponse(data));
    }

    public async mapNBAResponse(gameData: NBAData[]) {
        let { games } = this.state;

        for (const data of gameData) {
            const game = data.game;
            const status: Status = {
                period: getPeriodName(game.period, game.statusNum, game.startTimeUTC),
                state: getGameState(data),
                clock: game.clock,
                visitingScore: game.vTeam.score,
                homeScore: game.hTeam.score
            };

            const visiting: Team = {
                image: getTeamLogo(game.vTeam.triCode),
                name: getTeamName(game.vTeam.triCode),
                triCode: game.vTeam.triCode
            };

            const home: Team = {
                image: getTeamLogo(game.hTeam.triCode),
                name: getTeamName(game.hTeam.triCode),
                triCode: game.hTeam.triCode
            };

            const existingGame = games.findIndex((element: Game) => {
                return game.vTeam.triCode === element.visiting.triCode || game.vTeam.triCode === element.home.triCode;
            });

            if (existingGame >= 0) {
                games[existingGame].home = home;
                games[existingGame].visiting = visiting;
                games[existingGame].status = status;
            } else {
                games.push({
                    status,
                    visiting,
                    home
                });
            }
        }

        await this.setState({
            games
        });
    }

    public async mapRedditResponse(streamData: RedditStreamData[]) {
        const { games } = this.state;

        for (const stream of streamData) {
            const match = games.findIndex((element: Game) => {
                return element.visiting.name === stream.vTeam || element.home.name === stream.hTeam || element.visiting.name === stream.hTeam || element.home.name === stream.vTeam;
            });

            if (match >= 0) {
                games[match].streams = [{url: stream.streamLink, name: "Stream"}]
            }
        }

        await this.setState({
            games
        });
    }

    public render() {
        const { games } = this.state;

        if (games.length === 0) {
            return (
                <Grid fluid>
                    <Row center="md">
                        <Col>
                            <NonIdealState
                                icon={<Spinner />}
                                title="Loading.."
                                description="Searching for NBA games.."
                            >
                            </NonIdealState>
                        </Col>
                    </Row>
                </Grid>
            );
        }

        return (
            <Grid fluid>
                <Row center="md">
                    <Col>
                        <img src={lakers} alt="lakers" width="400" height="200" style={{marginLeft: "50px"}} />
                    </Col>
                </Row>
                <Row>
                    <Col mdOffset={4} md={6}>
                    <Row>
                        
                        {games.map((g,i) => {
                            return (
                                <Col key={i} md={6}>
                                    <Scorecard key={i} game={g} />
                                </Col>
                            )
                        })}
                    </Row>
                    </Col>
                </Row>
            </Grid>
        )
    }
}